import { useFavicon } from '@mantine/hooks'
import Head from 'next/head'
import { Fragment, memo, Suspense } from 'react'
import { useMount, useTitle, useUnmount } from 'react-use'
import { AnyFunction } from 'tsdef'
import { proxy, ref, useSnapshot } from 'valtio'
import { AgentProduct } from '~/configs/AgentProduct'
import { ICUserSubscriptionCheckAsFullscreen } from '~/modules/auth/components/UserSubscriptionCheckAsFullscreen'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { MeTypes } from '~/modules/SDK/me/MeTypes'
import { useSignalrBuild } from '~/modules/SDK/Signalr/useSignalrBuild'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument'
import { Preset_LoginPage } from '~/pages/heineken_template/_preset/preset_LoginPage'
import { Charting } from '~/pages/heineken_template/_private/Charting'
import { TemplatePage } from '~/pages/heineken_template/_private/TemplatePage'
import { component } from '~/utils/component'

export class TemplateProps {
  /**
   * 將會被 render 到 <Head> 裡面的：
   *
   * - `<title>`
   * - `<meta name='description'>`
   * - `<meta name='keywords'>`
   */
  readonly page = proxy({
    title: '',
    description: '',
    keywords: '',
  })

  /**
   * # 存放全局覆寫 cssset 的地方
   *
   * @example
   *   //
   *   // 例如你要在覆寫投顧層級的全局 FuiButton 的 cssset 可以塞這裡
   *   store.globalCssset = css`
   *     ${FuiButton.classes.Root} {
   *       outline: 1px solid red;
   *     }
   *   `
   */
  globalCssset = undefined as undefined | CSS

  onPageEnter?: AnyFunction = () => {
    //
  }

  onPageLeave?: AnyFunction = () => {
    //
  }

  permissions = {
    /**
     * - 它只管用來判斷「整頁」是否有讀取權，整頁都沒權，就展示 「layout.login」這個區塊
     * - 它不管「頁面中的小 HTML 區塊」是否有讀取權
     * - - 因此預期小區塊能夠被客製更細膩的「權限展示邏輯」
     *
     * @example
     *   //
     *   // 如果你有需要使用 fr_me 來取代 useMeStore, useFirebaseMeState, etc
     *   // 你可以
     *
     *   //
     *   // 前置安裝一次
     *   templateProps.hooks.add(fr_me.useInstall)
     *
     *   // 配置 fr_me 的 permission addon
     *   templateProps.permissions.pageview = fr_me.toTemplate.permissions.hasPermission(
     *     AgentProduct['futuresai@web'],
     *   )
     */
    pageview: meCheckHandlerCreateByProductNameWithExpiredAt(
      'web',
    ) as MeTypes.AddonAsTemplatePermissionPageview,
  }

  layout = {
    cssset: undefined as undefined | CSS,
    login: Preset_LoginPage as undefined | React.ComponentType | React.ReactElement,
    Row1: undefined as undefined | React.ComponentType | React.ReactElement,
    Row2: undefined as undefined | React.ComponentType | React.ReactElement,
    Row3: undefined as undefined | React.ComponentType | React.ReactElement,
    Row4: undefined as undefined | React.ComponentType | React.ReactElement,
    Row5: undefined as undefined | React.ComponentType | React.ReactElement,
    Row6: undefined as undefined | React.ComponentType | React.ReactElement,
    Col1: undefined as undefined | React.ComponentType | React.ReactElement,
    Col2: undefined as undefined | React.ComponentType | React.ReactElement,
    Col3: undefined as undefined | React.ComponentType | React.ReactElement,
    Col4: undefined as undefined | React.ComponentType | React.ReactElement,
    Col5: undefined as undefined | React.ComponentType | React.ReactElement,
    Col6: undefined as undefined | React.ComponentType | React.ReactElement,
    Drawer1: undefined as undefined | React.ComponentType | React.ReactElement,
    Drawer2: undefined as undefined | React.ComponentType | React.ReactElement,
    Charting: Charting as undefined | React.ComponentType | React.ReactElement,

    /**
     * # Template Root Providers
     *
     * - 提供 template 全局 ColX 與 RowX 所用到的 Providers 一個安置的地方
     * - 預設空 Providers
     *
     *   - 不影響排版
     *   - 不插入新 DOM
     */
    Providers: memo<ReactProps>(function EmptyProvider(props) {
      return <Fragment>{props.children}</Fragment>
    }),
  }

  /**
   * 提供熱插拔安裝一些額外功能的地方
   *
   * @example
   *   //
   *   // 安裝 fr_me 權限控管
   *   templateProps.hooks.add(fr_me.useInstall)
   *
   * @example
   *   //
   *   // 更多範例
   *   templateProps.hooks.add(() => {
   *     const state = useSnapshot(futuresai_store)
   *
   *     useEffect(() => {
   *       signalrStore2.addQuote(state.quoteSymbols)
   *
   *       return () => {
   *         signalrStore2.removeQuote(state.quoteSymbols)
   *       }
   *     }, [state.quoteSymbols])
   *   })
   */
  hooks = {
    _collection: new Set<AnyFunction>(),

    add<Func extends AnyFunction>(UseHookGetter: Func) {
      this._collection.add(UseHookGetter)
    },

    Provider: ref(
      component<ReactProps>(props => {
        this.hooks._collection.forEach(useHook => {
          useHook()
        })

        return <Fragment>{props.children}</Fragment>
      }),
    ),
  }

  constructor() {
    debugAPI.template.log(`new TemplateProps()`)
  }

  /**
   * # @deprecated
   *
   * 廢棄，因為會很難繼承附加上去
   *
   * ## 使用 {@link TemplateProps.hooks}
   *
   * 提供熱插拔安裝一些額外功能的地方
   *
   * @example
   *   templateProps.useGlobalHooks = () => {
   *     //
   *     // 安裝舊版 signalrStore
   *     useSignalrBuild({ reconnectCheckInterval: 10000 })
   *     useSignalrStoreValueOHLC.getState().useValueUpdateInterval(500)
   *
   *     //
   *     // 安裝 fr_me
   *     fr_me.useInstall()
   *   }
   */
  useGlobalHooks = () => {
    useSignalrBuild({ reconnectCheckInterval: 10000 })
    useSignalrStoreValueOHLC.getState().useValueUpdateInterval(500)
  }

  /**
   * @example
   *   //
   *   // 懶人 NextPage 用法
   *
   *   const templateProps = new TemplateProps()
   *
   *   //
   *   // 設定頁面 title ,etc
   *   templateProps.page.title = '即時指標'
   *   templateProps.page.description = '即時指標'
   *
   *   //
   *   // templateProps.layout.Col1 = Futuresai_Col1
   *   // templateProps.layout.Col2 = Futuresai_Col2
   *   // 略略略
   *   //
   *
   *   //
   *   // 然後這樣 export 出去，就完成了
   *   //
   *   export default templateProps.NextPage
   */
  NextPage = () => {
    debugAPI.template.log(`<templateProps.NextPage /> 渲染`, this)
    const title = useSnapshot(this.page).title || fr_agents.config.title

    useTitle(title)
    useFavicon(fr_agents.config.favicon)

    return (
      <Fragment>
        <Head>
          <title>{title}</title>
          <meta
            name='description'
            content={this.page.description}
          />
          <meta
            name='keywords'
            content={this.page.keywords}
          />
        </Head>

        <fr_instrument.DataProvider>
          <TemplatePage templateProps={this} />
        </fr_instrument.DataProvider>
      </Fragment>
    )
  }
}
